import Api from "./Api";
import Csrf from "./Csrf";

export default {
    async createAdmissionRound(newAdmissionRoundForm) {
        await Csrf.getCookie();
        return Api.post("/admission/process/create", newAdmissionRoundForm);
    },

    async getAllAdmissionRounds(institute_id=null, page, per_page, search) {
        await Csrf.getCookie();
        return Api.get("/admission/processes/" + institute_id + '/' + per_page + "?page=" + page + "&query=" + search);
    },

    async getInstituteAdmissionRounds(institute_id=null) {
        await Csrf.getCookie();
        return Api.get("/institute-admission-round/" + institute_id);
    },

    async getAdmissionRoundByID(id) {
        await Csrf.getCookie();
        return Api.get("/admission/process/" + id);
    },

    async getInstituteAdmissionRoundByID(id) {
        await Csrf.getCookie();
        return Api.get("/admission/process/institute/" + id);
    },

    async getInstituteTransferAdmissionRoundByID(institute_id) {
        await Csrf.getCookie();
        return Api.get("/transfer-admission/process/institute/" + institute_id);
    },

    async generateCandidateAdmissionRoundReport(id) {
        await Csrf.getCookie();
        return Api.get("/admission/generate-report/" + id, {
            responseType: "blob",
        });
    },

    async generateUndergraduateCandidateAdmissionRoundReportByProgrammeID(programme_id, admission_round_id) {
        // await Csrf.getCookie();
        return Api.get("/generate-undergraduates-admission-round-report-by-programme_id/" + programme_id + "/" + admission_round_id, {
            responseType: "blob",
        });
    },

    async getRunningAdmissionRoundNames() {
        await Csrf.getCookie();
        return Api.get("/admission/process/running");
    },

    async updateAdmissionRound(id, updateData) {
        await Csrf.getCookie();
        return Api.patch("/admission/process/update/" + id, updateData);
    },

    async endAdmissionRound(id) {
        await Csrf.getCookie();
        return Api.patch("/admission/process/end/" + id);
    },

    async toggleAdmissionRoundStatus(id, status) {
        await Csrf.getCookie();
        return Api.patch("/admission/process-toggle/" + id + '/' + status);
    },

    async getAdmissionRoundCount() {
        await Csrf.getCookie();
        return Api.get("/admission/process/count");
    },

    async updateAdmissionProcessingCharges(id, updateData) {
        await Csrf.getCookie();
        return Api.patch("/admission/process/charges/update/" + id, updateData);
    },

    async updateAdmissionRequirementsCheckList(id, updateData) {
        await Csrf.getCookie();
        return Api.patch(
            "/admission/requirements-checklist/update/" + id,
            updateData
        );
    },

    async updateApplicationNumberFormat(id, updateData) {
        await Csrf.getCookie();
        return Api.patch(
            "/admission/application-number-format/update/" + id,
            updateData
        );
    },


    async updateAdmissionOfferDocumentsChecklist(id, updateData) {
        await Csrf.getCookie();
        return Api.patch(
            "/admission/admission-offer-documents-checklist/update/" + id,
            updateData
        );
    },





    //---- assess candidates----

    async generateCandidatesAssessmentPoints(dataForm) {
        await Csrf.getCookie();
        return Api.post(
            "/admission/candidates/assessment-points/generate",
            dataForm
        );
    },

    async generateCandidatesOLevelPoints(dataForm) {
        await Csrf.getCookie();
        return Api.post(
            "/admission/candidates/o-level-points/generate",
            dataForm
        );
    },

    async generateCandidatesUtmeOrAlevelPoints(dataForm) {
        await Csrf.getCookie();
        return Api.post(
            "/admission/candidates/utme-points/generate",
            dataForm
        );
    },

    async generateCandidatesPostUtmePoints(dataForm) {
        await Csrf.getCookie();
        return Api.post(
            "/admission/candidates/post-utme-points/generate",
            dataForm
        );
    },

    async generateCandidatesAggregatePoints(dataForm) {
        await Csrf.getCookie();
        return Api.post(
            "/admission/candidates/aggregate-points/generate",
            dataForm
        );
    },

    async computeBestFiveOLevelSubject(dataForm) {
        await Csrf.getCookie();
        return Api.post("/admission/candidates/best-five-olevel/generate", dataForm);
    },

    async transferCandidate(dataForm) {
        await Csrf.getCookie();
        return Api.patch("/admission/candidate/transfer", dataForm);
    },

    async offerAdmission(dataForm) {
        await Csrf.getCookie();
        return Api.patch("/admission/candidate/offer-admission", dataForm);
    },

    //update admission acceptance deadline updateAcceptanceDeadline
    async updateAdmissionAcceptanceDeadline(dataForm) {
        await Csrf.getCookie();
        return Api.patch(
            "/undergraduate-admission/acceptance-deadline/update",
            dataForm
        );
    },

    //reset admission acceptance deadline
    async resetAdmissionAcceptanceDeadline(id) {
        await Csrf.getCookie();
        return Api.patch(
            "/undergraduate-admission/acceptance-deadline/reset/" + id
        );
    },
    //-- fetch admission candidates

    async getCandidatesByAdmissionRoundId(id,per_page, page) {
        await Csrf.getCookie();
        return Api.get('/admission/candidates/'+id+'/'+per_page+'?page='+page);
    },

    async getAdmissionModes(page, per_page=20, institute_id=null) {
        await Csrf.getCookie();
        return Api.get('/entry/modes/'+institute_id + '/' + per_page +'?page=' + page);
    },

    async getCandidatesByProgrammeId(
        programmeId,
        admissionRoundId,
        per_page,
        page
    ) {
        await Csrf.getCookie();
        return Api.get(
            "/admission/programme/candidates/" +
                admissionRoundId +
                "/" +
                programmeId +
                "/" +
                per_page +
                "?page=" +
                page
        );
    },

    async getClearanceCandidatesByProgrammeId(programmeId, admissionRoundId) {
        await Csrf.getCookie();
        return Api.get(
            "/admission/programme/clearance-candidates/" +
                admissionRoundId +
                "/" +
                programmeId
        );
    },

    async getApplicationsWithCandidatesByAdmissionRoundId(id) {
        await Csrf.getCookie();
        return Api.get("/admission/applications-with-candidates/" + id);
    },

    async getProgrammeApplicantions(programmeId, admissionRoundId) {
        await Csrf.getCookie();
        return Api.get(
            "/admission/programme/applications-with-candidates/" +
                programmeId +
                "/" +
                admissionRoundId
        );
    },

    async getAdmissionRoundCandidatesCount(id) {
        await Csrf.getCookie();
        return Api.get("/adms/count-total-candidates/" + id);
    },

    async downloadAdmissionRoundReport(id) {
        return Api.get("/admission/candidates/report/" + id, {
            responseType: "blob",
        });
        // return Api.get('/admission/candidates/report/'+id);
    },

    async downloadCandidatesAdmissionRoundReport(id) {
        return Api.get("/download-candidates-admission-report/" + id, {
            responseType: "blob",
        });
    },

    async toggleCandidateapplicationEdit(id, status) {
        await Csrf.getCookie();
        return Api.patch('/toggle-admission-round-edit-status/' + id + '/' + status);
    },

    async toggleAcceptanceEnabled(id, payload) {
        await Csrf.getCookie();
        return Api.patch('/toggle-acceptance-enabled-status/' + id , payload);
    },

    async useAsDefaultAdmissionRequirement(id) {
        await Csrf.getCookie();
        return Api.patch('/admission-round-requirement/default/' + id);
    },

    async copyAndPasteAdmissionRequirement(data) {
        await Csrf.getCookie();
        return Api.patch('/programme-requirements/paste', data);
    },

    async getAdmissionsWithRequirements(id) {
        await Csrf.getCookie();
        return Api.get('/admission-rounds-with-requirement/mode/' + id);
    },

    async toggleCheckProgrammeRequirements(id, status) {
        await Csrf.getCookie();
        return Api.patch('/admission-round/check-programme-requirements/' + id + '/' + status);
    },

    async toggleAllowAdmissionAgent(id, status) {
        await Csrf.getCookie();
        return Api.patch('/admission-round/allow-agent/' + id + '/' + status);
    },

    async toggleUseStudyCenter(id, status) {
        await Csrf.getCookie();
        return Api.patch('/admission-round/use-study-centre/' + id + '/' + status);
    },
};
